'use strict';

angular.module('appApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('terms-and-conditions', {
        url: '/terms-and-conditions',
        templateUrl: 'app/terms-and-conditions/terms-and-conditions.html',
        controller: 'TermsAndConditionsCtrl'
      });
  });
